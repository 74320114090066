import { Link } from 'react-router-dom';
import { Link as ReactLink } from 'react-scroll';

export default function Navbar() {
	return (
		<div className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b border-[#8E6B4B]  dark:border-lt-dark-secondary-border bg-[#8E4600] dark:bg-lt-dark-secondary-bg">
			<div>
				<a href="" className="font-bold text-base sm:text-xl text-[#F8D7A3] dark:text-[#8E4600]">
					Life Tracker
				</a>
			</div>
			<nav className="hidden sm:block w-6/12 md:w-1/4 ">
				<ul className="flex text-xs sm:text-base font-semibold justify-between text-[#F8D7A3] dark:text-white">
					<li>
						<ReactLink
							className="cursor-pointer"
							to="features"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to features"
						>
							Features
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="faq"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to FAQ"
						>
							FAQ
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="pricing"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to pricing"
						>
							Pricing
						</ReactLink>
					</li>
					{/* <li>
						<a href="/help">Support</a>
					</li>
					<li>
						<a href="/blogs">Blog</a>
					</li> */}
				</ul>
			</nav>
			<div className="navbar w-1/3 flex justify-center sm:hidden">
				<div className="navbar-center sm:hidden">
					<ul className="menu menu-md menu-horizontal px-1">
						<li>
							<details>
								<summary className="text-lt-lightCream dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
									Menu
								</summary>
								<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
									<li className="px-4">
										<ReactLink
											className="cursor-pointer active:bg-lt-lightCream active:text-lt-beige dark:bg-transparent active:dark:bg-lt-dark-popupBackground"
											to="features"
											smooth={true}
											duration={500}
											role="button"
											aria-label="Scroll to features"
										>
											Features
										</ReactLink>
									</li>
									<li className="px-4">
										<ReactLink
											className="cursor-pointer active:bg-lt-lightCream active:text-lt-beige dark:bg-transparent active:dark:bg-lt-dark-popupBackground"
											to="faq"
											smooth={true}
											duration={500}
											role="button"
											aria-label="Scroll to FAQ"
										>
											FAQ
										</ReactLink>
									</li>
									<li className="px-4">
										<ReactLink
											className="cursor-pointer active:bg-lt-lightCream active:text-lt-beige dark:bg-transparent active:dark:bg-lt-dark-popupBackground"
											to="pricing"
											smooth={true}
											duration={500}
											role="button"
											aria-label="Scroll to pricing"
										>
											Pricing
										</ReactLink>
									</li>
									{/* <li className="px-4 text-center">
										<a
											className="active:bg-lt-lightCream active:text-lt-beige dark:bg-transparent active:dark:bg-lt-dark-popupBackground"
											href="/help"
										>
											Support
										</a>
									</li>
									<li className="px-4">
										<a
											className="active:bg-lt-lightCream active:text-lt-beige dark:bg-transparent active:dark:bg-lt-dark-popupBackground"
											href="/blogs"
										>
											Blog
										</a>
									</li> */}
								</ul>
							</details>
						</li>
					</ul>
				</div>
			</div>
			<div>
				<Link
					className="bg-[#FFD28C] text-[#4F4F4F] py-2 px-4 sm:px-8 rounded-full border-2 border-[#6E6E40] dark:bg-lt-dark-primary-bg dark:text-white font-bold"
					type="button"
					to="/auth/login"
				>
					Sign In
				</Link>
			</div>
		</div>
	);
}
