import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function RefundPolicyEn() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life Tracker - Refund Policy</title>
				<meta name="description" content="Life Tracker's terms and conditions." />
			</Helmet>
			<header className="flex justify-between gap-2 items-center p-1 sm:p-4 w-full">
				<div className="flex justify-start items-center md:gap-2 w-1/2">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-[#8E4600] font-extrabold text-base md:text-2xl">
						Life Tracker
					</Link>
				</div>
				<div className="flex gap-1 sm:gap-4 text-sm md:text-xl">
					<Link
						className={`${language === 'en' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
	}`}
						to="/en/refund-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
	}`}
						to="/tr/refund-policy"
					>
						TR
					</Link>
				</div>
				<div className="flex justify-end gap-2 md:gap-4 w-3/4 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="max-w-3xl mx-auto p-6 mt-5">
				<h1 className="text-xl text-center font-bold mb-4">CANCELLATION, REFUND AND DELIVERY CONDITIONS</h1>
				<p className="mb-6">
					This Cancellation and Refund Policy (“<strong>Policy</strong>”) contains the cancellation and refund
					conditions for the products and services we offer to our Users as Kaan Mert (“Kaan Software Studio”).
				</p>

				<ol className="list-decimal list-inside mb-6">
					<li className="mb-4">
						This Policy is an integral part of the Distance Sales Agreement/Terms and Conditions ("
						<strong>Agreement</strong>") and the definitions in the Agreement are also valid in the Policy.
					</li>
					<li className="mb-4">
						If you purchase our Products/Services through Life Tracker, you are deemed to have accepted the Agreement.
					</li>
					<li className="mb-4">
						Kaan Software Studio offers Products/Services through Life Tracker that fall within the scope of “contracts
						for services whose execution is started with the approval of the consumer before the expiration of the right
						of withdrawal period” specified in subparagraph (h) of paragraph 1 of Article 15 titled “Exceptions to the
						Right of Withdrawal” of the Distance Agreements Regulation and “contracts for services performed instantly
						in electronic media and contracts for intangible goods delivered to the consumer instantly” specified in
						subparagraph (ğ) of paragraph 1 of the same article. For this reason, you cannot benefit from the right of
						withdrawal in accordance with the Regulation in terms of the Product/Service you have purchased. However,
						you can always cancel your membership. In case of cancellation, it will be possible to continue to benefit
						from the Products/Services until the end of the term already paid for.
					</li>
					<li className="mb-4">
						The User accepts that the service offered is in the category of “electronically deliverable product” and
						therefore cannot be returned. No physical delivery will be made due to the nature of the Products/Services.
					</li>
				</ol>
			</div>
		</div>
	);
}
