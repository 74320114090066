import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

const faqData = [
	{
		question: 'What is the main purpose of this app?',
		answer:
			'The app allows users to track various aspects of their daily lives, such as mood, water intake, and step count, using customizable tables where each table represents a year and each box within the table represents a day. Users can assign colors to different keywords (e.g., "happy," "stressed," "angry" for a daily mood table) and select the appropriate data for each day. As time passes, the table becomes more colorful and detailed. In addition to this, users can earn achievements and coins by completing different tasks within the app. They also gain streaks, and for each day they log in, they earn coins based on their current streak count. These coins can be used to purchase stickers from the shop, which they can use to decorate their virtual journal in the "My Journal" section. Moreover, users can connect with friends, view their journals, stickers, and achievements, and keep track of their activities. They can also compete on the leaderboard in various categories, trying to reach the top 10.'
	},
	{
		question: 'Can I use the Life Tracker for free?',
		answer:
			'Absolutely! In the free version, you won’t be able to earn coins through daily streak bonuses and can have a maximum of one table. Other than that, you have access to all the features available in the pro version.'
	},
	{
		question: 'What if Life Tracker is not for me?',
		answer:
			"We want you to be fully satisfied with your experience on Life Tracker. If you decide to cancel your subscription, you can continue to enjoy premium features until the end of your current billing period. This means you'll have full access for the time you’ve already paid for, and no further charges will apply once your subscription term ends. We're here to help if you have any questions or need assistance."
	},
	{
		question: 'Can I access my journal and tables from different devices?',
		answer:
			'Yes, your data is stored securely, and you can access your journal and tables from any device by logging into your account.'
	},
	{
		question: 'Is my data secure?',
		answer:
			'Absolutely. We take data security seriously and ensure that all your information is stored securely and is accessible only by you.'
	}
];

export default function FAQ() {
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const handleClick = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};
	return (
		<Element name="faq" className="flex flex-col text-center items-center gap-5 mt-20 px-4 w-full">
			<header className="font-bold text-3xl sm:text-5xl dark:text-[#8E4600]">FAQ</header>
			<h2 className="sm:font-semibold sm:text-xl dark:text-[#5D4037]">
				You can find answers to a few main topics here. If you have more detailed questions, please visit our{' '}
				<Link to="/help/faqs" className="text-lt-beige underline">
					FAQs
				</Link>
				.
			</h2>
			<div className="  mt-8 w-full md:w-3/4 base:w-1/2">
				{faqData.map((item, index) => (
					<div
						key={index}
						className="mb-4 border border-lt-beige dark:border-[#6E6E40] rounded-md bg-[#FAEDCD] dark:bg-lt-dark-popupBackground "
					>
						<button
							onClick={() => handleClick(index)}
							className="w-full text-left py-3 px-4 bg-[#FAEDCD] hover:bg-lt-beige focus:outline-none rounded-md dark:bg-lt-dark-popupBackground"
						>
							<div className="flex justify-between items-center ">
								<span className="font-bold text-[#8E4600] text-lg sm:text-xl dark:text-white">{item.question}</span>
								<span>{openIndex === index ? '-' : '+'}</span>
							</div>
						</button>
						<Transition
							show={openIndex === index}
							enter="transition ease-out duration-300"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-200"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div className="px-4 pb-3 text-[#8E4600] bg-[#FAEDCD] dark:text-white rounded-md text-start dark:bg-lt-dark-popupBackground">
								{item.answer}
							</div>
						</Transition>
					</div>
				))}
			</div>
		</Element>
	);
}
