import { IconArrowLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import blog1 from '/src/assets/blog/blog1.svg';

export default function Blog() {
	const idFromUrl = useParams().id;
	const [isDarkMode, setIsDarkMode] = useState(false);

	useEffect(() => {
		const darkModeEnabled =
			localStorage.getItem('theme') === 'dark' || document.documentElement.classList.contains('dark');
		setIsDarkMode(darkModeEnabled);
	}, []);

	const blogs = [
		{
			id: 1,
			title: 'Track Your Life, Not Just Your Habits: A Comprehensive Approach to Daily Life Management',
			description: `
  <p style="font-size: 22px;"><strong>Why Tracking More Than Just Habits Matters</strong></p>
  <p style="font-size: 18px; color: ${isDarkMode ? '#a8a0a0' : '#4b5563'};">Managing your life effectively starts with recognizing the value of small, daily actions. Often, it's the small things—how much water you drink, your sleep quality, or your mood—that lay the foundation for larger changes. Our app promotes a holistic approach, encouraging users to track various aspects of daily life, revealing connections between them. By organizing these elements, you can gain a comprehensive view of your behavior, identify patterns, and make more informed decisions, leading to positive, impactful changes over time.

With consistent tracking, you begin to understand how seemingly unrelated actions influence each other. For instance, you might notice that drinking more water improves your focus, or that a daily walk elevates your mood. This level of insight empowers you to prioritize and build sustainable habits that enhance your well-being, helping you make intentional, positive changes with lasting effects.</p>
  <br />

  <p style="font-size: 22px;"><strong>Go Beyond Simple Choices for Tracking</strong></p>
  <p style="font-size: 18px; color: ${isDarkMode ? '#a8a0a0' : '#4b5563'};">Life Tracker allows you to monitor your habits with more than just a "yes" or "no." Customize each tracking table with up to six distinct options. If you're tracking water intake, for instance, set different ranges (e.g., 0-500ml, 500-1000ml) and assign a unique color to each. This way, as your table fills up over time, it visually reflects your progress and provides insightful data. Life Tracker's approach makes tracking not only simpler but also more engaging, keeping you motivated on your journey.</p>

  <br />

  <p style="font-size: 22px;"><strong>Track with Intervals or Key-Values – Your Choice!</strong></p>
  <p style="font-size: 18px; color: ${isDarkMode ? '#a8a0a0' : '#4b5563'};">One of Life Tracker's standout features is its flexibility: track with intervals (like pages read) or key-values (like mood). For interval tables, each value has a defined start, end, and unit (e.g., ml, hours), allowing up to six ranges with unique colors. With key-value tables, you simply assign colors to each state (e.g., happy, sad). This flexible approach makes tracking intuitive and visually engaging. If it sounds complex, try creating a table, and you’ll see how easy it is!</p>
 <br />
    <p style="font-size: 22px;"><strong>Gamify Your Progress</strong></p>
    <p style="font-size: 18px; color: ${isDarkMode ? '#a8a0a0' : '#4b5563'};">Staying consistent in tracking habits or routines can be challenging. Many of us start strong but lose momentum over time, eventually dropping the routine altogether. Life Tracker combats this by introducing a virtual journal where you can decorate pages with stickers bought with in-app gold. Plus, your journal can be viewed by friends, giving a fun, social aspect. Don't worry—any private tables remain hidden. This gamified approach makes routine tracking engaging, helping you stay motivated and consistent over the long run.</p>
    
`,
			date: '28 Oct 2024',
			image: blog1
		}
	];

	return (
		<div>
			<Link to="/blogs" className="flex items-center absolute left-5 top-5 text-3xl font-bold text-lt-beige">
				<IconArrowLeft className="w-16 h-16 mr-2" />
			</Link>
			<div className="p-5 sm:p-20 lg:p-40 pt-10 mt-10">
				{blogs.map((blog) => {
					if (blog.id === Number(idFromUrl)) {
						return (
							<div key={blog.id} className="flex flex-col items-center justify-center gap-4">
								<img className="w-full" src={blog.image} alt={blog.title} />
								<p className="w-full flex justify-end text-lt-dark-inputPlaceholder">{blog.date}</p>
								<h1 className="font-bold text-2xl sm:text-4xl mb-5">{blog.title}</h1>
								<span
									className="text-base sm:text-lg leading-8"
									dangerouslySetInnerHTML={{ __html: blog.description }}
								></span>
							</div>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
}
