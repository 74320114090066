import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function RefundPolicyTr() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life Tracker - Refund Policy</title>
				<meta name="description" content="Life Tracker's terms and conditions." />
			</Helmet>
			<header className="flex justify-between gap-2 items-center p-1 sm:p-4 w-full">
				<div className="flex justify-start items-center md:gap-2 w-1/2">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-[#8E4600] font-extrabold text-base md:text-2xl">
						Life Tracker
					</Link>
				</div>
				<div className="flex gap-1 sm:gap-4 text-sm md:text-xl">
					<Link
						className={`${language === 'en' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
	}`}
						to="/en/refund-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
	}`}
						to="/tr/refund-policy"
					>
						TR
					</Link>
				</div>
				<div className="flex justify-end gap-2 md:gap-4 w-3/4 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="max-w-3xl mx-auto p-6 mt-5">
				<h1 className="text-xl text-center font-bold mb-4">İPTAL, İADE VE TESLİMAT KOŞULLARI</h1>
				<p className="mb-6">
					İşbu İptal ve İade Politikası (“<strong>Politika</strong>”), Kaan Mert (“Kaan Software Studio”) olarak
					kullanıcılarımıza sunduğumuz ürün ve hizmetlere yönelik iptal ve iade koşullarını içermektedir.
				</p>

				<ol className="list-decimal list-inside mb-6">
					<li className="mb-4">
						İşbu Politika, Mesafeli Satış Sözleşmesi'nin ("<strong>Sözleşme</strong>") ayrılmaz bir parçası olup;
						Sözleşme'de yer alan tanımlar Politika'da da geçerlidir.
					</li>
					<li className="mb-4">
						Life Tracker üzerinden Ürünlerimizi/Hizmetlerimizi satın aldığınız takdirde, Kullanıcı Sözleşmesi’ni kabul
						etmiş sayılırsınız.
					</li>
					<li className="mb-4">
						Kaan Software Studio, Life Tracker üzerinden Mesafeli Sözleşmeler Yönetmeliği’nin “Cayma Hakkının
						İstisnaları” başlıklı 15. maddesinin 1. fıkrasının (h) bendinde belirtilen “cayma hakkı süresi sona ermeden
						önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler” ve aynı maddenin 1. fıkrasının
						(ğ) bendinde belirtilen “elektronik ortamda anında ifa edilen hizmetler ile tüketiciye anında teslim edilen
						gayri maddi mallara ilişkin sözleşmeler” kapsamına giren Ürün/Hizmetler sunmaktadır. Bu sebeple satın
						aldığınız Ürün/Hizmet bakımından Yönetmelik uyarınca cayma hakkından yararlanamazsınız. Ancak üyeliğinizi
						her zaman iptal edebilirsiniz. İptal halinde ödemesi yapılan dönemin sonuna kadar Ürün/Hizmetlerden
						yararlanmaya devam edilebilecektir.
					</li>
					<li className="mb-4">
						Kullanıcı, sunulan hizmetin “Elektronik ortamda teslim edilebilir ürün” kategorisinde olması sebebiyle iade
						yapılamayacağını kabul eder. Ürün/Hizmetlerin niteliği gereği fiziksel bir teslimat yapılmayacaktır.
					</li>
				</ol>
			</div>
		</div>
	);
}
