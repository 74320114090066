import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/button/button';
import { useResendVerificationEmailMutation } from '../../services/api/auth';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { RootState } from '../../states/store';
import { resetEmail } from '../../states/auth/emailSlice';
import oneMoreStep from '/src/assets/auth/oneMoreStep.webp';
import { Helmet } from 'react-helmet';

export default function EmailVerification() {
	const isCartDomain = window.location.hostname.includes('cart.');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [resendVerificationEmail, { isLoading }] = useResendVerificationEmailMutation();
	const [countdown, setCountdown] = useState<number>(60);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	const { email } = useSelector((state: RootState) => state.email);

	const handleResendVerification = async () => {
		if (!email) {
			toast.error('Email address not found.');
			return;
		}

		setIsButtonDisabled(true);
		setCountdown(60);
		try {
			const response = await resendVerificationEmail({ email }).unwrap();
			if (response) {
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again.');
			}
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isButtonDisabled && countdown > 0) {
			timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
		} else if (countdown === 0) {
			setIsButtonDisabled(false);
		}
		return () => clearTimeout(timer);
	}, [isButtonDisabled, countdown]);

	const clearEmail = () => {
		dispatch(resetEmail());
	};

	useEffect(() => {
		if (!isAuthenticated) {
			if (!email) {
				navigate('/auth/register');
				toast.error('Email address not found.');
			}
		}
	}, [email, isAuthenticated, navigate]);

	return (
		<div>
			<Helmet>
				<title>Life Tracker - Email Verification</title>
				<meta name="description" content="Email verification page." />
			</Helmet>
			<div className="flex flex-col justify-center items-center min-h-screen gap-4 w-full">
				{isCartDomain ? (
					<a href="https://lifetracker.live" className="text-[#8E4600] font-extrabold text-2xl">
						Life Tracker
					</a>
				) : (
					<Link to="/" className="text-[#8E4600] font-extrabold text-2xl">
						Life Tracker
					</Link>
				)}
				<h2 className="font-bold text-5xl mb-2 text-center">JUST ONE MORE STEP...</h2>
				<p className="text-center mb-6">
					A verification link has been sent to your email address. Please verify your email to continue.
				</p>
				<img
					style={{ width: '600px' }}
					src={oneMoreStep}
					alt="email-verification"
					className="my-10 max-w-[350px] max-h-[175px]  sm:w-[600px] sm:h-[300px]"
				/>
				<Button
					onClick={handleResendVerification}
					type="button"
					variant="btn-primary-light"
					className="btn btn-primary"
					disabled={isButtonDisabled || isLoading}
				>
					{isLoading ? (
						<span className="loading loading-spinner loading-md"></span>
					) : isButtonDisabled ? (
						`Resend in ${countdown}s`
					) : (
						'Resend Verification Email'
					)}
				</Button>
				<Link onClick={clearEmail} className="underline" to="/auth/login">
					Go to login
				</Link>
			</div>
		</div>
	);
}
