import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import KVKK from '/src/assets/KVKK.pdf';
import Logo from '/src/assets/logo.svg';

export default function PrivacyPolicyTr() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div className="flex flex-col h-screen">
			<Helmet>
				<title>Life Tracker - Privacy Policy</title>
				<meta name="description" content="Life Tracker's privacy policy." />
			</Helmet>
			<header className="flex justify-between gap-2 items-center p-1 sm:p-4 w-full">
				<div className="flex justify-start items-center md:gap-2 w-1/2">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-[#8E4600] font-extrabold text-base md:text-2xl">
						Life Tracker
					</Link>
				</div>
				<div className="flex gap-1 sm:gap-4 text-sm md:text-xl">
					<Link
						className={`${language === 'en' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/en/privacy-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/tr/privacy-policy"
					>
						TR
					</Link>
				</div>
				<div className="flex justify-end gap-2 md:gap-4 w-3/4 md:w-1/2">
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					<Link
						to="/en/refund-policy"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Refund Policy
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-2xs md:text-sm base:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="p-6 max-w-4xl mx-auto mt-5 md:mt-10">
				<h1 className="text-2xl font-bold mb-4">KİŞİSEL VERİLERİN İŞLENMESİ HAKKINDA AYDINLATMA METNİ</h1>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">1. Giriş</h2>
					<p className="mb-2">
						<strong>Kaan Mert (“Kaan Software Studio”)</strong> olarak kişisel verilerinizin gizliliği, güvenliği ve
						korunması en önemli önceliklerimiz arasındadır. Bu bilinçle, Life Tracker uygulamasının kullanılması
						sırasında tarafımıza iletilen tüm kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu (
						<strong>“KVKK”</strong> veya <strong>“Kanun”</strong>) ve ilgili mevzuata uygun olarak işlenerek muhafaza
						edilmesi hususunda gerekli prosedürleri uygulamaktayız.
					</p>
					<p>
						İşbu Aydınlatma Metni ile KVKK uyarınca ve “Veri Sorumlusu” sıfatıyla, Life Tracker uygulaması ile
						sunduğumuz hizmet kapsamında edindiğimiz kişisel verilerinizin nasıl işlendiğine dair sizleri
						bilgilendirmeyi amaçlıyoruz.
					</p>
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">2. Hangi Kişisel Verilerinizi İşliyoruz?</h2>
					<p className="mb-2">Life Tracker uygulamasını kullandığınızda aşağıdaki kişisel verileriniz işlenmektedir:</p>
					<table className="min-w-full border border-gray-300 mb-4">
						<tbody>
							<tr>
								<td className="border px-4 py-2 font-bold">HESAP BİLGİLERİ</td>
								<td className="border px-4 py-2">Kullanıcı ID (*), Kullanıcı adı (*)</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold">İLETİŞİM BİLGİLERİ</td>
								<td className="border px-4 py-2">Life Tracker hesabınıza kayıtlı e-posta adresi (*)</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">PROFİL FOTOĞRAFI</td>
								<td className="border px-4 py-2">Life Tracker hesabınıza yüklediğiniz profil fotoğrafı</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">HESAP ARKA PLAN FOTOĞRAFI</td>
								<td className="border px-4 py-2">Life Tracker hesabınıza yüklediğiniz hesap arka plan fotoğrafı</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">MÜŞTERİ BİLGİLERİ</td>
								<td className="border px-4 py-2">
									Life Tracker içerisindeki tablolara girilen veri geçmişi (*), Tablo bilgileri (*), Feedback formu
									doldurulduğu takdirde formda yer verilen bilgiler
								</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">İŞLEM GÜVENLİĞİ BİLGİLERİ</td>
								<td className="border px-4 py-2">
									Kullanıcı işlem kayıtları (*), giriş yapma yöntemi (e-posta/Google aracılığıyla) (*)
								</td>
							</tr>
						</tbody>
					</table>
					<p className="text-xs text-gray-500">
						* İşlenmesi mutlaka gerekli olan veriler * ile işaretlenmiştir. Diğer verilerin işlenmesi kullanıcının
						veriyi Life Tracker üzerinden Kaan Software Studio ile paylaşmasına bağlıdır.
					</p>
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">
						3. Kişisel Verilerinizin İşlenme Yöntemleri, Amaçları ve Hukuki Sebepleri Nelerdir?
					</h2>
					<p className="mb-2">
						Kişisel verileriniz, Kaan Software Studio tarafından tamamen veya kısmen otomatik olan yollarla,
						yararlandığınız hizmetin yer aldığı internet sitesi üzerinden elektronik ortamda toplanmakta ve KVKK’nın 5.
						maddesinde düzenlenen hukuki sebeplere dayalı olarak işlenmektedir.
					</p>
					<p className="mb-2">
						İşlenen kişisel verilerinizin işlenme amaçları ve hukuki sebeplerine aşağıda yer verilmiştir:
					</p>
					<h3 className="text-lg font-semibold mb-1">3.1 Üyelik ve Hesap Oluşturma Süreci:</h3>
					<p className="mb-2 font-medium">
						<strong>Bu amaçla işlenen kişisel verileriniz:</strong> Hesap bilgileriniz, iletişim bilgileriniz, müşteri
						bilgileriniz, işlem güvenliği bilgileriniz, profil fotoğrafınız, hesap arka plan fotoğrafınız.
					</p>
					<h3 className="font-semibold">Amaçlar:</h3>
					<ul className="list-disc ml-6 mb-4">
						<li>Üyelik sözleşmesinin kurulması ve ifasına yönelik süreçlerin yürütülmesi,</li>
						<li>Üye profilinin/hesabın oluşturulması ve kapatılması süreçlerinin yönetilmesi,</li>
						<li>İletişim bilgilerinin doğrulanması,</li>
						<li>Hizmet satın alım işlemlerinizin gerçekleştirilmesine yönelik faaliyetlerin yürütülmesi,</li>
						<li>İş faaliyetlerimizin yürütülmesi ve denetimi,</li>
						<li>Operasyonlarımızın güvenliğinin sağlanması,</li>
						<li>Tabi olduğumuz mevzuattan kaynaklanan yükümlülüklerin yerine getirilmesi,</li>
						<li>Uygulama faaliyetlerinin mevzuata uygun olarak yürütülmesi,</li>
						<li>
							Mevzuattan kaynaklanan yükümlülüklerimiz uyarınca veri paylaşımı ve muhafazası yükümlülüklerinin yerine
							getirilmesi.
						</li>
					</ul>
					<p className="mb-4">
						<span className="font-semibold">Hukuki Sebepler:</span> Kanunlarda açıkça öngörülmüş olması (KVKK m.5/2-a),
						Üyelik sözleşmesinin kurulması ve ifası için gerekli olması (KVKK m.5/2-c), Hukuki yükümlülüklerimizin
						yerine getirilmesi için zorunlu olması (KVKK m.5/2-ç).
					</p>
				</section>
				<h3 className="text-lg font-semibold mb-1">3.2 Bilgi Güvenliği, Risk, Denetim ve Kontrol Süreçleri:</h3>
				<p className="mb-2 font-medium">
					<strong>Bu kategoride işlenen kişisel verileriniz:</strong> Hesap bilgileriniz, iletişim bilgileriniz, müşteri
					bilgileriniz, işlem güvenliği bilgileriniz
				</p>
				<h3 className="font-semibold">Amaçlar:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>Bilgi güvenliği süreçlerinin yürütülmesi ve şüpheli işlemlere yönelik inceleme yapılması,</li>
					<li>Operasyonların güvenliğinin sağlanması,</li>
					<li>Life Tracker ile sunulan hizmetlerin kalitesinin kontrolü, geliştirilmesi ve iyileştirilmesi,</li>
					<li>Şikâyet yönetimi yapılması.</li>
				</ul>
				<p className="mb-4">
					<span className="font-semibold">Hukuki Sebepler:</span> Üyelik sözleşmesinin ifası için gerekli olması (KVKK
					m.5/2-c), Meşru menfaatlerimiz için veri işlenmesinin zorunlu olması (KVKK m.5/2-f).
				</p>
				<h3 className="text-lg font-semibold mb-1">3.3 Hukuki İşlemlerin Yürütülmesi Süreci:</h3>
				<p className="mb-2 font-medium">
					<strong>Bu kategoride işlenen kişisel verileriniz:</strong> Hesap bilgileriniz, iletişim bilgileriniz, müşteri
					bilgileriniz, işlem güvenliği bilgileriniz
				</p>
				<h3 className="font-semibold">Amaçlar:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>
						Hukuk ve dava işlerinin yürütülmesi, hukuki danışmanlık süreçlerinin yürütülmesi, hukuki taleplerinizin
						cevaplandırılması, ilgili kişi olarak usulüne uygun başvurularınızın incelenmesi ve yanıtlanması,
					</li>
					<li>Faaliyetlerin mevzuata uygun yürütülmesi,</li>
					<li>Tabi olduğumuz mevzuattan kaynaklanan yükümlülüklerin yerine getirilmesi,</li>
					<li>Talep edilmesi halinde yetkili kişi, kurum ve kuruluşlarla bilgi paylaşılması,</li>
					<li>Hukuki bir ihtilaf olması halinde bir hakkın kullanılması ve korunması.</li>
				</ul>
				<p className="mb-4">
					<span className="font-semibold">Hukuki Sebepler:</span> Hukuki yükümlülüklerimizin yerine getirilmesi için
					zorunlu olması (KVKK m.5/2-ç), Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu
					olması (KVKK m.5/2-e).
				</p>

				<h2 className="text-xl font-semibold mb-2">
					4. Kişisel Verileriniz Kimlere, Hangi Sebeplerle Aktarılmaktadır?
				</h2>
				<p className="mb-2">
					Kişisel verileriniz KVKK’nın 8. ve 9. maddelerine uygun olarak, amaçla bağlantılı, sınırlı ve ölçülü şekilde
					aşağıdaki hallerde <strong>Kaan Software Studio</strong> çalışanlarına, görevlilerine, kanunen yetkili kamu
					kurum ve kuruluşlarına, hizmetlerin ve/veya faaliyetlerin yürütülmesi için hizmet aldığımız veya birlikte
					çalıştığımız yurt içinde ve yurt dışında bulunan iş ortaklarına, veri tabanı ve hizmet sağlayıcılarına
					aktarılır:
				</p>

				<h3 className="text-lg font-semibold mb-1">4.1 Aktarılan kişisel verileriniz:</h3>
				<p className="mb-2 font-medium">
					Hesap bilgileriniz, iletişim bilgileriniz, müşteri bilgileriniz, işlem güvenliği bilgileriniz
				</p>
				<p className="mb-4">
					<span className="font-semibold">Amaçlar:</span> Sunulan hizmetlerle ilgili olarak altyapı ve bilişim hizmeti
					sağlayanlar dahil tedarikçilerimizden destek alınması, finans ve muhasebe işlerinin yürütülmesi.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Hukuki Sebepler:</span> Hukuki yükümlülüklerimizin yerine getirilmesi için
					zorunlu olması (KVKK m.5/2-ç), Kişisel verilerinizin yurt dışına aktarılmasına açık rızanızın bulunması (KVKK
					m.9).
				</p>

				<h3 className="text-lg font-semibold mb-1">4.2 Aktarılan kişisel verileriniz:</h3>
				<p className="mb-2 font-medium">
					Hesap bilgileriniz, iletişim bilgileriniz, müşteri bilgileriniz, işlem güvenliği bilgileriniz
				</p>
				<p className="mb-4">
					<span className="font-semibold">Amaçlar:</span> Hukuki yükümlülüklerimizin yerine getirilmesi, bu kapsamda
					yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, hukuki süreçlerin yürütülmesi ve faaliyetlerimizin
					mevzuata uygun olarak yürütülmesi.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Hukuki Sebepler:</span> Hukuki yükümlülüklerimizin yerine getirilmesi için
					zorunlu olması (KVKK m.5/2-ç).
				</p>
				<section className="py-6 max-w-4xl mx-auto">
					<h2 className="text-xl font-semibold mb-4">
						5. Kişisel Verilerinizin Korunmasına Yönelik Hangi Haklara Sahipsiniz?
					</h2>
					<p className="mb-2">
						KVKK’nın 11. Maddesi uyarınca kişisel verilerinizin korunmasına yönelik aşağıda sayılan haklara sahipsiniz:
					</p>
					<ul className="list-inside ml-6 mb-4" style={{ listStyleType: 'lower-alpha' }}>
						<li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
						<li>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</li>
						<li>
							Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
						</li>
						<li>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
						<li>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
						<li>
							Kanun’un 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok
							edilmesini isteme,
						</li>
						<li>
							Kişisel verilerinizin KVKK’nın 7. maddesi kapsamında silinmesine/yok edilmesine veya eksik/yanlış işlenmiş
							olması halinde düzeltilmesine ilişkin taleplerinizin üçüncü kişilere bildirilmesini isteme,
						</li>
						<li>
							İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi sonucu aleyhinize bir
							sonucun çıkmasına itiraz etme,
						</li>
						<li>
							Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın
							giderilmesini talep etme.
						</li>
					</ul>
				</section>
				<section className="py-6 max-w-4xl mx-auto">
					<h2 className="text-xl font-semibold mb-4">
						6. Kişisel Verilerinizin Korunmasına Yönelik Talepleriniz için İletişim
					</h2>
					<p className="mb-2">
						KVKK’nın 11. maddesinde düzenlenen haklarınıza ilişkin taleplerinizi, Başvuru Formunu (
						<a className="font-semibold" href={KVKK} download="Life-Tracker-KVKK-Başvuru-Formu.pdf">
							buradan
						</a>
						) doldurup imzalayarak sistemimize kayıtlı e-posta adresiniz üzerinden{' '}
						<a className="hover:underline font-semibold" href="mailto:dev@lifetracker.live">
							dev@lifetracker.live
						</a>{' '}
						e-posta adresimize elektronik olarak iletebilirsiniz.
					</p>
					<p className="mb-4">
						Başvurularınız, KVKK’nın 13. maddesi uyarınca en kısa sürede ve en geç 30 (otuz) gün içerisinde ücretsiz
						şekilde sonuçlandırılacak ve tarafınıza bilgilendirme yapılacaktır.
					</p>
					<h3 className="text-lg font-semibold mb-2">7. Veri Sorumlusunun İletişim Bilgileri</h3>
					<table className="min-w-full border border-gray-300">
						<tbody>
							<tr>
								<td className="border px-4 py-2 font-semibold">Ad-Soyad</td>
								<td className="border px-4 py-2">Kaan MERT</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">Unvan</td>
								<td className="border px-4 py-2">Kaan MERT</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">E-posta Adresi</td>
								<td className="border px-4 py-2">kaanmert.dev@gmail.com</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">Adres</td>
								<td className="border px-4 py-2">Yağcılar Mahallesi Saadet Sokağı No: 18/- Adapazarı/ Sakarya</td>
							</tr>
						</tbody>
					</table>
				</section>
			</div>
		</div>
	);
}
