import { useEffect, useState } from 'react';
import loadingScreen1 from '/src/assets/animations/loadingScreen/loadingScreen1.svg';
import loadingScreen2 from '/src/assets/animations/loadingScreen/loadingScreen2.svg';
import loadingScreen3 from '/src/assets/animations/loadingScreen/loadingScreen3.svg';
import loadingScreen4 from '/src/assets/animations/loadingScreen/loadingScreen4.svg';

const LoadingScreen = () => {
	const images = [loadingScreen1, loadingScreen2, loadingScreen3, loadingScreen4];
	const [currentImage, setCurrentImage] = useState(images[0]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTimeout(() => {
				setCurrentImage((prev) => {
					const currentIndex = images.indexOf(prev);
					return images[(currentIndex + 1) % images.length];
				});
			}, 500); // Delay for smooth transition
		}, 1000); // Total duration for each step

		return () => clearInterval(intervalId);
	}, []);
	return (
		<div className="h-screen flex items-center justify-center bg-lt-lightCream dark:bg-lt-dark-popupBackground ">
			<div id="loading-screen">
				<img id="loading-step" src={currentImage} alt="Loading Step" />
			</div>
		</div>
	);
};

export default LoadingScreen;
