import { Link } from 'react-router-dom';
import './aboutUs.css';
import { Helmet } from 'react-helmet';

export default function AboutUs() {
	return (
		<div className="flex flex-col aboutUs h-screen">
			<Helmet>
				<title>About Us</title>
				<meta
					name="description"
					content="Discover the story behind Life Tracker. Learn about our mission, values, and the team dedicated to helping you track your life and achieve your goals."
				/>
			</Helmet>
			<header className="flex justify-between gap-2 items-center p-10">
				<Link to="/" className="text-[#8E4600] dark:text-white text-3xl font-bold hover:text-lt-beige">
					Life Tracker
				</Link>
				<div className="flex gap-4">
					<Link
						to="/blogs"
						className="text-lg sm:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Blogs
					</Link>
					<Link
						to="/help"
						className="text-lg sm:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Help
					</Link>
					<Link
						to="/"
						className="text-lg sm:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="max-w-2xl mx-auto p-8 border border-lt-beige bg-white dark:bg-lt-dark-popupBackground rounded-xl">
				<h1 className="text-4xl font-bold text-center mb-6">About Us</h1>
				<p className="text-lg mb-4">
					Welcome to <span className="font-semibold">Life Tracker</span>! We're a team driven by passion and dedication
					to help you gain deeper insights into your daily life. Founded by Kaan, a solo software engineer with years of
					experience in software development, design, and user-centric solutions, Life Tracker embodies the journey of
					combining technical expertise with an eye for impactful user experiences.
				</p>
				<p className="text-lg mb-4">
					Our mission is to make life tracking not only effective but also enjoyable. We believe that understanding and
					visualizing your routines can empower you to lead a more balanced, productive, and fulfilling life. Through
					innovative tools, social sharing features, and personalization, we aim to create a platform that truly
					supports your growth.
				</p>
				<p className="text-lg mb-4">
					Every feature you see is designed with care, tested thoroughly, and built to inspire and motivate. Whether
					you’re setting goals, monitoring habits, or simply enjoying the satisfaction of progress, Life Tracker is here
					to be your companion.
				</p>
				<p className="text-lg text-center font-semibold mt-8">
					Thank you for joining us on this journey. Let’s track, share, and celebrate every moment, together.
				</p>
			</div>
		</div>
	);
}
