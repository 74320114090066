import { Link } from 'react-router-dom';
import './blog.css';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useSubscribeMutation } from '../../../services/api/email';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import blog1 from '/src/assets/blog/blog1.svg';

export default function Blogs() {
	const blogs = [
		{
			id: 1,
			title: 'Track Your Life, Not Just Your Habits: A Comprehensive Approach to Daily Life Management',
			description:
				'Uncover deeper insights into your daily routines. By tracking every aspect of your life—not just habits—you can see how small actions like drinking water or a good night’s sleep affect your overall mood and productivity. This holistic approach helps you make meaningful adjustments and craft a lifestyle that truly supports your well-being.',
			date: '28 Oct 2024',
			image: blog1
		}
	];
	const [email, setEmail] = useState('');
	const [subscribeReq, { isLoading: isSubsReqLoading }] = useSubscribeMutation();

	const subscribe = async () => {
		try {
			const response = await subscribeReq({ email }).unwrap();
			if (response) {
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setEmail('');
		}
	};

	return (
		<div className="relative">
			<Helmet>
				<title>Blogs</title>
				<meta
					name="description"
					content="Discover insightful articles on habit tracking, self-improvement, and staying motivated with engaging content and practical tips. Learn how to organize your life with custom bullet journals, colorful stickers, and more!"
				/>
			</Helmet>

			<div className="subscribe flex flex-col items-center justify-center px-0 py-20 sm:p-28 md:p-40 gap-4">
				<Link
					to="/"
					className="text-[#8E4600] dark:text-white absolute left-2 top-2 sm:left-10 sm:top-10 text-3xl font-bold hover:text-lt-beige"
				>
					Life Tracker
				</Link>
				<Link
					to="/help"
					className="absolute  right-44  top-2 sm:right-64 sm:top-10 text-lg sm:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
				>
					Help
				</Link>
				<Link
					to="/about-us"
					className="absolute  right-20  top-2 sm:right-32 sm:top-10 text-lg sm:text-xl font-bold  bg-lt-lightCream border dark:bg-lt-dark-popupBackground dark:text-white border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
				>
					About Us
				</Link>
				<Link
					to="/"
					className="absolute right-2 top-2 sm:right-10 sm:top-10 text-lg sm:text-xl font-bold  bg-lt-lightCream dark:bg-lt-dark-popupBackground dark:text-white border border-lt-beige p-1 sm:p-2 rounded-xl text-black hover:text-lt-beige"
				>
					Home
				</Link>

				<h1 className="font-bold text-2xl sm:text-4xl text-center px-4 sm:px-0">
					Track, Reflect, Improve: Your Life in Pixels
				</h1>
				<h2 className="text-base sm:text-xl text-center  px-4 sm:px-0">
					Subscribe to our newsletter to receive the latest blog posts, updates, and exclusive offers.
				</h2>
				<div className="border border-lt-beige p-0.5 sm:p-1.5 bg-white rounded-lg mt-4">
					<input
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						type="text"
						placeholder="Enter your email"
						className="border-none outline-none ring-0"
					/>
					<button
						disabled={isSubsReqLoading}
						onClick={subscribe}
						className="bg-lt-beige text-white border border-lt-beige rounded-lg py-1.5 sm:py-2 px-2 sm:px-4"
					>
						{isSubsReqLoading ? <span className="loading loading-spinner loading-xs"></span> : 'Subscribe'}
					</button>
				</div>
			</div>
			<div className="flex flex-col gap-5 p-4 mb-20 sm:mb-0 sm:p-10">
				<h3 className="text-2xl font-semibold">All blog posts</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
					{blogs.map((blog) => (
						<Link to={`/blog/${blog.id}`} className="flex flex-col gap-2 hover:text-lt-beige">
							<img className="w-full" src={blog.image} alt={blog.title} />
							<span className="text-xs text-lt-dark-inputPlaceholder">{blog.date}</span>
							<h3 className="font-semibold text-xl ">{blog.title}</h3>
							<h4 className="text-sm">{blog.description}</h4>
						</Link>
					))}
				</div>
			</div>
		</div>
	);
}
