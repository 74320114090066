import { IconCrown, IconSquareCheckFilled, IconSquareX } from '@tabler/icons-react';
import { useState } from 'react';
import { Element } from 'react-scroll';
import { Link } from 'react-router-dom';

const freePlan = [
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to the virtual journal and the ability to decorate it with stickers.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Earn coins by completing achievements.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'View the activities and journals of users you follow.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to detailed information about your tables.'
	},
	// {
	// 	icon: <IconSquareCheckFilled />,
	// 	desc: 'Sync across devices.'
	// },
	{
		icon: <IconSquareX />,
		desc: 'Earn coins through streak bonuses.'
	},
	{
		icon: <IconSquareX />,
		desc: 'Unlimited table creation (Limited to 1 table creation).'
	}
];

const proPlan = [
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to the virtual journal and the ability to decorate it with stickers.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Earn coins by completing achievements.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'View the activities and journals of users you follow.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Access to detailed information about your tables.'
	},
	// {
	// 	icon: <IconSquareCheckFilled />,
	// 	desc: 'Sync across devices.'
	// },
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Earn coins through streak bonuses.'
	},
	{
		icon: <IconSquareCheckFilled />,
		desc: 'Unlimited table creation.'
	}
];

export default function Pricing() {
	const cartBaseUrl = import.meta.env.VITE_SITE_URL;
	console.log('cart', cartBaseUrl);
	const [isToggled, setIsToggled] = useState(false);
	const [text, setText] = useState<'Monthly' | 'Yearly'>('Monthly');

	const toggle = () => {
		setIsToggled(!isToggled);
	};
	return (
		<Element name="pricing" className="w-full md:w-3/4 flex flex-col text-center gap-5 mt-10 px-4">
			<header className="font-bold text-3xl sm:text-5xl dark:text-[#8E4600]">Pricing</header>
			<h2 className="sm:font-semibold text-base sm:text-xl dark:text-[#5D4037]">
				Subscribe to Premium and access all the features!
			</h2>
			<div className="flex flex-col items-center md:items-stretch md:flex-row gap-4">
				<div className=" w-full md:w-1/2">
					<div className="border border-lt-beige dark:border-[#6E6E40] rounded-lg p-6 flex flex-col gap-2 text-start">
						<h4 className="text-[#8E4600] font-bold text-xl sm:text-2xl">Free</h4>
						<h5 className="min-h-14 text-sm sm:text-base">
							The basic plan. Access to all essential features for smooth app usage.
						</h5>
						<div className="flex items-center border-b border-lt-beige dark:border-[#6E6E40] pb-0  min-h-[150px]">
							<h2 className="text-[#8E4600] font-extrabold text-4xl">0$</h2>
							<h4 className="text-[#8E4600]"> / Month</h4>
						</div>
						<ul className="mt-5 flex flex-col pb-32 text-start">
							{freePlan.map((item, index) => (
								<li key={index} className="flex gap-2 min-h-[50px]">
									<div className="min-w-5">{item.icon}</div>

									<h5 className=" text-sm xl:text-base">{item.desc}</h5>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className="w-full md:w-1/2">
					<div className="border border-lt-beige dark:border-[#6E6E40] rounded-lg p-6 flex flex-col gap-2 h-full text-start bg-gradient-to-tr from-lt-beige via-[#e9ccab] dark:from-[#121212] dark:to-lt-dark-popupBackground to-lt-lightCream">
						<div className="flex justify-between">
							<div className="flex items-center">
								<h4 className="text-[#8E4600] font-bold text-xl sm:text-2xl">Premium</h4>
								<IconCrown className="text-[#8E4600] ml-2" />
							</div>

							<div className="flex items-center justify-center gap-2">
								<h4 className="font-semibold sm:text-lg">Monthly</h4>
								<div
									className={`relative w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
										isToggled ? 'bg-green-500' : 'bg-gray-300'
									}`}
									onClick={() => {
										toggle();
										setText(text === 'Monthly' ? 'Yearly' : 'Monthly');
									}}
								>
									<div
										className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform ${
											isToggled ? 'translate-x-8' : 'translate-x-0'
										}`}
									/>
								</div>
								<h4 className="font-semibold sm:text-lg mr-2">Yearly</h4>
							</div>
						</div>

						<h5 className="min-h-14 text-sm sm:text-base">
							The premium plan. Includes all the features to start track your life with your online journal.
						</h5>
						<div className="flex items-center border-b border-lt-beige dark:border-[#6E6E40] pb-0 min-h-[150px]">
							{text === 'Monthly' ? (
								<>
									<h3 className="text-[#8E4600] font-extrabold text-4xl">3.99$</h3>
									<h5 className="text-[#8E4600]"> / Month</h5>
								</>
							) : (
								<div className="flex flex-col">
									{' '}
									<div className="flex items-end">
										<div>
											<h3 className="text-[#8E4600] font-semibold text-xl line-through decoration-2">48$</h3>
											<h3 className="text-[#8E4600] font-extrabold text-4xl">32$</h3>
										</div>
										<h5 className="text-[#8E4600]"> / Yearly</h5>
									</div>
									<h6>%33 Discount</h6>
								</div>
							)}
						</div>
						<ul className="mt-5 flex flex-col pb-8 text-start">
							{proPlan.map((item, index) => (
								<li key={index} className="flex gap-2 min-h-[50px]">
									<div className="min-w-5">{item.icon}</div>

									<h5 className=" text-sm xl:text-base">{item.desc}</h5>
								</li>
							))}
						</ul>
						<div className="w-full flex justify-center">
							<a
								href={`${cartBaseUrl}/your-cart?planType=${text}`}
								className="w-10/12 rounded-3xl bg-lt-lightCream dark:bg-lt-dark-primary-bg border border-[#6E6E40] font-bold py-4 items-center justify-center flex"
							>
								Go Premium
							</a>
						</div>
					</div>
				</div>
			</div>
		</Element>
	);
}
