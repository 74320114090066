import gamifyIt from '/src/assets/landing-page/gamify.webp';
import leaderboard from '/src/assets/landing-page/leaderboard.webp';
import friends from '/src/assets/landing-page/friends.webp';
import streaks from '/src/assets/landing-page/streaks.webp';
import { Element } from 'react-scroll';

export default function Features() {
	return (
		<Element name="features" className="relative w-full flex justify-center px-4">
			<div className="flex flex-col items-center gap-8">
				<header className="font-bold text-3xl sm:text-5xl dark:text-[#8E4600]">Features</header>
				<h2 className="sm:font-semibold text-base sm:text-xl dark:text-[#5D4037]">
					Features of the Life Tracker you will love
				</h2>
				<div className="grid grid-cols-3 grid-rows-5 sm:grid-cols-4 sm:grid-rows-2 gap-2 w-full xl:w-3/4">
					<div className="relative p-2 sm:p-6 dark:bg-lt-dark-popupBackground bg-[#FAEDCD] border flex flex-col justify-between h-[150px] sm:h-[562px] border-lt-beige dark:border-[#6E6E40] rounded-lg col-start-1 col-end-4 row-start-1 row-end-2  sm:row-start-1 sm:row-end-3 sm:col-start-1 sm:col-end-2">
						<div className="flex flex-col gap-1 sm:gap-2 justify-center">
							<h1 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl base:text-4xl z-10">
								Gamify It!
							</h1>
							<h2 className="text-[#8E4600] dark:text-white font-normal sm:font-semibold text-sm base:text-sm z-10">
								Make your virtual journal fun by adding your tables and decorating the pages with stickers.
							</h2>
						</div>

						<img
							className="w-[150px] sm:w-auto  absolute sm:static right-5 bottom-0 h-[150px] sm:h-auto"
							src={gamifyIt}
							alt="gamify-it"
							loading="lazy"
						/>
					</div>
					<div className="relative  p-2 sm:p-6 border bg-[#FAEDCD] dark:bg-lt-dark-popupBackground border-lt-beige dark:border-[#6E6E40] rounded-lg col-start-1 col-end-3 row-start-2 row-end-4 sm:col-start-2 sm:col-end-4 sm:row-start-1 sm:row-end-2">
						<h3 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl base:text-4xl z-10">
							Leaderboard! Where’s the crown ?
						</h3>
						<h4 className="text-[#8E4600] dark:text-white sm:font-semibold text-sm base:text-sm w-full sm:w-6/12 mt-1 sm:mt-5 z-10">
							See the activities of your friends and race with them on different categories.
						</h4>
						<img
							className="w-[200px] sm:w-[200px] h-auto md:w-[275px] absolute right-2 -bottom-1.5"
							src={leaderboard}
							alt="leaderboard"
							loading="lazy"
						/>
					</div>
					<div className="relative p-2 sm:p-6 border dark:bg-lt-dark-popupBackground bg-[#FAEDCD] border-lt-beige dark:border-[#6E6E40] rounded-lg col-start-3 col-end-4 row-start-2 row-end-4 sm:col-start-4 sm:col-end-5 sm:row-start-1 sm:row-end-2">
						<div className="flex flex-col gap-1 sm:gap-2 justify-center">
							<h3 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl base:text-4xl z-10">
								Friends
							</h3>
							<h4 className="text-[#8E4600] dark:text-white sm:font-semibold text-sm base:text-sm z-10">
								Follow your friends and see their journals!
							</h4>
						</div>

						<img
							className="absolute bottom-0 left-1/2 transform -translate-x-1/2"
							src={friends}
							alt="friends"
							loading="lazy"
						/>
					</div>
					<div className="p-2 sm:p-6 flex flex-col items-center border dark:bg-lt-dark-popupBackground bg-[#FAEDCD] border-lt-beige dark:border-[#6E6E40] rounded-lg col-start-1 col-end-2 row-start-4 row-end-6 sm:col-start-2 sm:col-end-3 sm:row-start-2 sm:row-end-3">
						<div className="flex flex-col gap-1 sm:gap-2 justify-center">
							<h3 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl base:text-4xl">Streaks</h3>
							<h4 className="text-[#8E4600] dark:text-white sm:font-semibold text-sm base:text-sm">
								Keep earning Streak and Coins by logging in every day!
							</h4>
						</div>
						<img
							className="absolute bottom-5 w-[125px] sm:w-[150px] md:w-[200px] h-auto base:w-[250px]"
							src={streaks}
							alt="streaks"
							loading="lazy"
						/>
					</div>
					<div className="overflow-hidden p-2 sm:p-6  flex flex-col items-center justify-between px-0 border dark:bg-lt-dark-popupBackground bg-[#FAEDCD] border-lt-beige dark:border-[#6E6E40] rounded-lg col-start-2 col-end-4 row-start-4 row-end-6 sm:col-start-3 sm:col-end-5 sm:row-start-2 sm:row-end-3">
						<div className="flex flex-col gap-1 sm:gap-2 justify-center max-w-full">
							<h3 className="text-[#8E4600] dark:text-white font-bold text-xl sm:text-2xl base:text-4xl overflow-hidden whitespace-nowrap z-10">
								Stickers Stickers Stickers Stickers Stickers Stickers
							</h3>
							<h4 className="text-[#8E4600] dark:text-white px-2 sm:font-semibold text-sm base:text-sm z-10">
								Customize your journal pages with the various numbers of stickers!
							</h4>
						</div>

						<div className="relative flex w-full justify-around items-center sm:px-4">
							<img
								className="object-contain absolute sm:static bottom-28 left-2 w-[100px] h-[100px] sm:w-[75px] sm:h-[75px] md:h-[100px] md:w-[100px] base:w-[150px] base:h-[150px]"
								src="https://stickerbucketlifetracker.s3.amazonaws.com/Cactus+With+Flower.webp"
								alt="sticker 1"
								loading="lazy"
							/>
							<img
								className="object-contain absolute sm:static bottom-16 right-0 w-[100px] h-[100px] sm:w-[75px] sm:h-[75px] md:h-[100px] md:w-[100px] base:w-[150px] base:h-[150px]"
								src="https://stickerbucketlifetracker.s3.amazonaws.com/Llama.webp"
								alt="sticker 2"
								loading="lazy"
							/>
							<img
								className="object-contain absolute sm:static bottom-0 left-2 w-[100px] h-[100px] sm:w-[75px] sm:h-[75px] md:h-[100px] md:w-[100px] base:w-[150px] base:h-[150px]"
								src="https://stickerbucketlifetracker.s3.amazonaws.com/Yoga.webp"
								alt="sticker 3"
								loading="lazy"
							/>
						</div>
					</div>
				</div>
			</div>
		</Element>
	);
}
