import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../states/store';
import { useLogout } from '../hooks/useLogout';
import { isCustomErrorResponse } from '../utils/errorUtils';
import toast from 'react-hot-toast';
import { useLazyRefreshTokenQuery } from '../services/api/auth';

const TokenRefreshProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	const [refreshTokenRequest] = useLazyRefreshTokenQuery();
	const auth = useSelector((state: RootState) => state.auth);
	const logout = useLogout();

	useEffect(() => {
		if (auth.isAuthenticated) {
			const refreshToken = async () => {
				try {
					await refreshTokenRequest({});
				} catch (error) {
					logout();
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred.');
					}
				}
			};

			const interval = setInterval(refreshToken, 13 * 60 * 1000); // 13 dakika
			return () => clearInterval(interval);
		}
	}, [auth.isAuthenticated, refreshTokenRequest, logout]);

	return <>{children}</>;
};

export default TokenRefreshProvider;
