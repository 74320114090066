const iconList = [
	'ti ti-anchor',
	'ti ti-alarm',
	'ti ti-award',
	'ti ti-badge',
	'ti ti-bed',
	'ti ti-bike',
	'ti ti-bolt',
	'ti ti-basket',
	'ti ti-bell',
	'ti ti-book',
	'ti ti-briefcase',
	'ti ti-bulb',
	'ti ti-brush',
	'ti ti-calendar',
	'ti ti-camera',
	'ti ti-car',
	'ti ti-checklist',
	'ti ti-cloud',
	'ti ti-coffee',
	'ti ti-compass',
	'ti ti-credit-card',
	'ti ti-download',
	'ti ti-droplet',
	'ti ti-eraser',
	'ti ti-file',
	'ti ti-flag',
	'ti ti-flower',
	'ti ti-folder',
	'ti ti-globe',
	'ti ti-gift',
	'ti ti-heart',
	'ti ti-hammer',
	'ti ti-headphones',
	'ti ti-home',
	'ti ti-hourglass',
	'ti ti-key',
	'ti ti-leaf',
	'ti ti-list',
	'ti ti-lock',
	'ti ti-map',
	'ti ti-medal',
	'ti ti-microphone',
	'ti ti-moon',
	'ti ti-music',
	'ti ti-mountain',
	'ti ti-notebook',
	'ti ti-package',
	'ti ti-paperclip',
	'ti ti-pencil',
	'ti ti-phone',
	'ti ti-plane',
	'ti ti-paint',
	'ti ti-pizza',
	'ti ti-puzzle',
	'ti ti-photo',
	'ti ti-rainbow',
	'ti ti-rocket',
	'ti ti-run',
	'ti ti-settings',
	'ti ti-shield',
	'ti ti-star',
	'ti ti-stopwatch',
	'ti ti-sun',
	'ti ti-tag',
	'ti ti-swimming',
	'ti ti-trash',
	'ti ti-trophy',
	'ti ti-truck',
	'ti ti-umbrella',
	'ti ti-user',
	'ti ti-upload',
	'ti ti-video',
	'ti ti-wallet',
	'ti ti-windmill'
];

export default iconList;
