import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { useVerifyEmailMutation } from '../../services/api/auth';
import { resetEmail } from '../../states/auth/emailSlice';
import { RootState } from '../../states/store';
import { Helmet } from 'react-helmet';

export const VerifyEmail = () => {
	const isCartDomain = window.location.hostname.includes('cart.');
	const dispatch = useDispatch();
	const [verifyEmailMutation, { isLoading }] = useVerifyEmailMutation();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const navigate = useNavigate();
	const location = useLocation();

	const clearEmail = () => {
		dispatch(resetEmail());
	};

	useEffect(() => {
		if (!isAuthenticated) {
			const verifyEmail = async () => {
				const params = new URLSearchParams(location.search);
				const token = params.get('token');

				if (token) {
					try {
						const response = await verifyEmailMutation(token).unwrap();

						if (response) {
							toast.success(response.message);
						}
					} catch (error) {
						navigate('/auth/register');

						if (isCustomErrorResponse(error)) {
							toast.error(error.data.message);
						} else if (error instanceof Error) {
							toast.error(error.message);
						} else {
							toast.error('An unknown error occurred. Please try again later.');
						}
					}
				} else {
					navigate('/auth/register');
					toast.error('Error verifying email. Please try again.');
				}
			};

			verifyEmail();
		}
	}, [location.search, navigate, isAuthenticated, verifyEmailMutation]);

	if (isLoading) {
		return (
			<div className="flex flex-col justify-center items-center min-h-screen">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);
	}

	return (
		<div className="flex flex-col justify-center items-center min-h-screen">
			<Helmet>
				<title>Life Tracker - Email Verification</title>
				<meta name="description" content="Email verification successful!" />
			</Helmet>
			{isCartDomain ? (
				<a href="https://lifetracker.live" className="text-[#8E4600] font-extrabold text-2xl">
					Life Tracker
				</a>
			) : (
				<Link to="/" className="text-[#8E4600] font-extrabold text-2xl">
					Life Tracker
				</Link>
			)}
			<h2 className="text-2xl font-bold">Email Verification Successful!</h2>
			<p className="mt-4">Your email has been successfully verified.</p>
			<Link onClick={clearEmail} to="/auth/login" className="mt-4 underline">
				Go to Login
			</Link>
		</div>
	);
};
