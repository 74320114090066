import { IconSearch, IconTrash } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { IUser, useLazySearchUsersQuery } from '../../../services/api/social';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import placeholder from '../../../assets/no-profile-picture.svg';
import { Link } from 'react-router-dom';
import { useDebounce } from '../../../hooks/useDebounce';

export default function SearchProfile() {
	const [query, setQuery] = useState('');
	const debouncedQuery = useDebounce(query, 500); // 500ms gecikme
	const [results, setResults] = useState<IUser[] | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const [getUsers] = useLazySearchUsersQuery();

	useEffect(() => {
		if (debouncedQuery) {
			if (debouncedQuery.length < 3) {
				setResults(undefined);
				return;
			}
			setIsLoading(true);

			getUsers(debouncedQuery)
				.unwrap()
				.then((response) => {
					setResults(response.data);
				})
				.catch((error) => {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please try again.');
					}
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setResults(undefined);
		}
	}, [debouncedQuery, getUsers]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(e.target.value);
	};

	const clearInput = () => {
		setQuery('');
		setResults(undefined);
	};

	return (
		<div className="relative flex flex-col w-10/12">
			<div className="flex items-center px-2 h-11 bg-lt-lightCream dark:bg-lt-dark-popupBorder border border-lt-beige dark:border-lt-dark-secondary-border rounded-default relative">
				<IconSearch />
				<input
					value={query}
					onChange={handleChange}
					className="input input-bordered bg-transparent border-none outline-none focus:outline-none w-full pl-2"
					placeholder="Search Profile"
					type="text"
				/>
				{query && <IconTrash onClick={clearInput} className="text-red-500 cursor-pointer absolute right-2" />}
			</div>
			{isLoading ? (
				<div className="absolute top-10 w-full flex justify-center border border-lt-beige bg-lt-lightCream rounded-default dark:bg-transparent min-h-10">
					<span className="loading loading-spinner loading-md"></span>
				</div>
			) : (
				<>
					{results && results.length > 0 && (
						<div className="bg-lt-lightCream border-lt-beige border  absolute top-[45px] w-full z-50 max-h-[200px] overflow-y-auto">
							<ul className="rounded-default dark:bg-transparent">
								{results.map((user) => (
									<li key={user._id} className="w-full flex">
										<Link
											to={`/dashboard/profile/${user._id}`}
											className="flex items-center w-full p-2 dark:bg-lt-dark-popupBorder  hover:bg-lt-beige rounded-default"
										>
											<img
												className="w-8 h-8 rounded-full object-cover mr-2"
												src={user.profileImgUrl ?? placeholder}
												alt={user.username}
											/>
											{user.username}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}{' '}
					{results && results.length === 0 && debouncedQuery.length >= 3 && (
						<div className="absolute top-[45px] w-full z-50">
							<ul className="bg-lt-lightCream border border-lt-beige rounded-default dark:bg-transparent">
								<li className="w-full flex">
									<span className="p-2">No Results Found</span>
								</li>
							</ul>
						</div>
					)}
				</>
			)}
		</div>
	);
}
