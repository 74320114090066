import { Link } from 'react-router-dom';
import PaymentMethods from '/src/assets/paymentMethods.png';

export default function Footer() {
	const handleClick = () => {
		window.scrollTo(0, 0);
	};
	return (
		<footer className="flex justify-center h-[450px] gap-2 bg-gradient-to-b w-full mt-10 from-lt-lightCream to-[#8E4600] dark:from-[#121212] dark:to-lt-dark-popupBackground p-2 sm:p-4 md:p-8">
			<div className="flex flex-col justify-between max-w-[1000px]">
				<div className="flex justify-center mx-auto gap-4 sm:gap-40  pt-6">
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Social</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-white font-semibold">
								<li>
									<a className="hover:underline" href="https://twitter.com/Kaanmrttt">
										Twitter
									</a>
								</li>
								<li>
									<a className="hover:underline" href="https://www.linkedin.com/in/kaan-mert-7b2809208/">
										Linkedin
									</a>
								</li>
								<li>
									<a className="hover:underline" href="https://www.instagram.com/kaanmerttt/">
										Instagram
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Learn More</h2>
						<div className="mt-4">
							<ul className="text-[#333333] dark:text-white text-sm sm:text-xl font-semibold flex flex-col gap-2">
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/about-us">
										About Us
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/blogs">
										Blog
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/help">
										Support
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/contact" className="hover:underline">
										Contact
									</Link>
								</li>
								<li>
									<a className="hover:underline" href="mailto:dev@lifetracker.live">
										Email Us
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Legal</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-white font-semibold">
								<li>
									<Link onClick={handleClick} to="/en/privacy-policy" className="hover:underline">
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/en/terms-of-service" className="hover:underline">
										Terms of Service
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/en/refund-policy" className="hover:underline">
										Refund Policy
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="flex items-start justify-between border-t pt-5 border-[#C8A165] dark:border-[#6E6E40]">
					<a className="text-[#C8A165] text-xl sm:text-3xl font-bold dark:text-[#8E4600]" href="">
						Life Tracker
					</a>
					<div className="flex flex-col gap-2">
						<img className="w-[400px]" src={PaymentMethods} alt="Payment Methods" />
						<h6 className="text-[#333333] dark:text-white text-2xs sm:text-xs md:text-base  font-semibold text-end">
							{' '}
							© 2024 Life Tracker. All rights reserved.
						</h6>
					</div>
				</div>
			</div>
		</footer>
	);
}
