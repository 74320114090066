import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useGoogleLoginMutation, useLoginMutation } from '../../services/api/auth';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { LoginRequest } from '../../types/auth.types';
import { loginSuccess } from '../../states/auth/authSlice';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { persistor, resetAction } from '../../states/store';
import { Helmet } from 'react-helmet';
import Logo from '/src/assets/logo.svg';

export default function Login() {
	const isCartDomain = window.location.hostname.includes('cart.');
	console.log('test', window.location.hostname);
	console.log('test2', isCartDomain);
	const dispatch = useDispatch();
	const [loginData, setLoginData] = useState<LoginRequest>({
		email: '',
		password: ''
	});
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [login, { isLoading }] = useLoginMutation();
	const [googleLoginRequest] = useGoogleLoginMutation();
	const emailInputRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (emailInputRef.current) {
			emailInputRef.current.focus();
		}
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setLoginData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};
	const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const response = await login(loginData).unwrap();

			if (response) {
				dispatch(resetAction());
				await persistor.purge();
				dispatch(loginSuccess({ email: loginData.email }));
				navigate('/');
			}
		} catch (error) {
			console.log(error);
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleLoginSuccess = async (response: any) => {
		try {
			const res = await googleLoginRequest({
				token: response.credential
			}).unwrap();
			if (res) {
				dispatch(loginSuccess({ email: res.email }));
				navigate('/dashboard/my-tables');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	return (
		<div className="flex min-h-screen flex-col items-center">
			<Helmet>
				<title>Life Tracker - Login</title>
				<meta name="description" content="Login to Life Tracker" />
			</Helmet>
			<div className="w-full flex justify-start p-4 items-center gap-2">
				<img src={Logo} alt="logo" className="w-10 h-10" />
				{isCartDomain ? (
					<a href="https://lifetracker.live" className="text-[#8E4600] font-extrabold text-2xl">
						Life Trackerss
					</a>
				) : (
					<Link to="/" className="text-[#8E4600] font-extrabold text-2xl">
						Life Trackertt
					</Link>
				)}
			</div>
			<div className="flex flex-col items-center py-10 gap-4 w-full bg-white dark:bg-lt-dark-popupBackground max-w-[700px] min-h-[725px] border border-lt-beige rounded-lg shadow-xl">
				<h2 className="font-bold text-5xl mb-4">Login</h2>
				<GoogleLogin
					text="signin_with"
					size="large"
					onSuccess={handleLoginSuccess}
					onError={() => {
						console.log('Login Failed');
					}}
				/>
				<div className="flex items-center justify-center my-4 w-full">
					<div className="w-1/4 h-px bg-lt-beige"></div>
					<span className="mx-4 text-gray-500 font-medium">OR</span>
					<div className="w-1/4 h-px bg-lt-beige"></div>
				</div>
				<form onSubmit={handleLogin} className="flex flex-col justify-center items-center gap-4 w-full">
					<Input
						type="text"
						placeholder="Email"
						name="email"
						required
						value={loginData.email}
						onChange={handleChange}
						ref={emailInputRef}
					/>
					<div className="w-full flex flex-col items-start max-w-xs">
						<Input
							type={isPasswordVisible ? 'text' : 'password'}
							value={loginData.password}
							onChange={handleChange}
							placeholder="Password"
							name="password"
							icon={
								isPasswordVisible ? (
									<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
								) : (
									<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
								)
							}
						/>
						<Link to={'/auth/forgot-password'} className="underline mt-1 text-lt-text-light dark:text-white text-sm">
							Forgot Password?
						</Link>
					</div>

					{!isLoading ? (
						<>
							<Button
								className="px-12 mt-4"
								disabled={loginData.email == '' || loginData.password == ''}
								type="submit"
								variant="btn-primary-light"
							>
								Login
							</Button>
						</>
					) : (
						<button className="btn btn-square">
							<span className="loading loading-spinner"></span>
						</button>
					)}
				</form>

				<h6>
					Don't have an account?{' '}
					<Link to={'/auth/register'} className="underline">
						Register
					</Link>
				</h6>
				<h6>
					<Link to="/auth/whitelist" className="underline">
						Please enter your email for the whitelist request.
					</Link>
				</h6>
			</div>
		</div>
	);
}
