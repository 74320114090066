import Button from '../button/button';
import { CustomModuleProps } from './customModule.types';

export default function CustomModule({
	id,
	title,
	description,
	buttonText,
	onButtonClick,
	isLoading
}: CustomModuleProps) {
	const closeModal = () => {
		(document.getElementById(id) as HTMLDialogElement)?.close();
	};

	return (
		<dialog id={id} className="modal">
			<div className="modal-box border-2 border-lt-beige bg-lt-cream dark:bg-lt-dark-popupBackground dark:border-lt-dark-popupBorder">
				<form method="dialog">
					<button disabled={isLoading} className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
						✕
					</button>
				</form>
				<h3 className="font-bold text-xl">{title}</h3>
				<p className="py-4">{description}</p>

				<div className="modal-action">
					<form method="dialog" className="flex w-full justify-between">
						<button disabled={isLoading} onClick={closeModal} type="reset" className="btn btn-ghost">
							Cancel
						</button>

						<Button disabled={isLoading} variant="btn-primary-light" type="button" onClick={onButtonClick}>
							{isLoading ? (
								<div className="w-full h-full flex justify-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<>{buttonText}</>
							)}
						</Button>
					</form>
				</div>
			</div>
		</dialog>
	);
}
